.selectedTimeSlot {
  background-color: #0e8cc7;
  color: #fff;
}

.timeSlotIsdisabled {
  opacity: 0.9;
  cursor: no-drop !important;
  color: #000 !important;
  background-color: #bfbfbf;
}
